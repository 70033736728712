.loderBody {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #fff;
}

.loader {
  display: flex;
}

.load, 
.load-reverse {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.load {
  border-top: 10px solid #3c0d99;
  animation: spin 1s linear infinite;
}

.load-reverse {
  border-top: 10px solid #3c0d99;
  animation: spin-reverse 1s linear infinite;
}

@keyframes spin {
  from { 
    transform: rotate(0deg); 
  }
  to { 
    transform: rotate(360deg); 
  }
}

@keyframes spin-reverse {
  from { 
    transform: rotate(360deg); 
  }
  to { 
    transform: rotate(0deg); 
  }
}