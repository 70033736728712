@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zeyada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lacquer&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lancelot&display=swap");

@font-face {
  font-family: "gilroy";
  src: url(./gilroy/Gilroy-Bold.ttf);
}

@font-face {
  font-family: "gilroy";
  src: url(./gilroy/Gilroy-Regular.ttf);
}

:root {
  --primary-100: rgb(0 119 194);
  --primary-200: rgb(89 165 245);
  --primary-300: rgb(200 255 255);
  --accent-100: rgb(0 191 255);
  --accent-200: rgb(0 97 154);
  --text-100: rgb(51 51 51);
  --text-200: rgb(92 92 92);
  --bg-100: rgb(255 255 255);
  --bg-200: rgb(245 245 245);
  --bg-300: rgb(0 97 154 / 5%);
  --border-color: rgb(222 226 230);
  --font-size-thin: 12px;
  --font-size-regular: 13px;
  --font-size-solid: 15px;
  --red_color: rgb(255 0 0);
  --check_font_family: "gilroy";
  --font_family: "gilroy";
  --check_active_color: rgb(0 128 0);
  /* Active Color */
  --check_inactive_color: rgb(255 0 0);
  /* Inactive Color */
  --check_pending_color: rgb(128 128 128);
  /* Pending Color */

  --bg_active_color: rgb(0 183 235 / 15%);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html,
body {
  padding: 0;
  min-height: 100%;
  font-family: var(--font_family);
  box-sizing: inherit;
  scroll-behavior: smooth;
  height: -webkit-fill-available;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
}

.checkFontClass {
  font-family: var(--check_font_family);
}

.checkActiveColor {
  color: var(--check_active_color);
}

.checkInactiveColor {
  color: var(--check_inactive_color);
}

.checkPendingColor {
  color: var(--check_pending_color);
}

/* ScrollBar  */

::-webkit-scrollbar,
.black::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

::-webkit-scrollbar-thumb,
.blank::-webkit-scrollbar-thumb {
  background-color: var(--accent-200);
}

::-webkit-scrollbar-track,
.blank::-webkit-scrollbar-track,
.black::-webkit-scrollbar-track {
  background: var(--bg-300);
}

.black::-webkit-scrollbar-thumb {
  background-color: var(--text-200);
}

.blank::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.home_page_section {
  width: 100%;
  min-height: 87.9vh;
  display: grid;
  align-content: center;
}

.header_section {
  background: var(--accent-200);
  color: var(--bg-100);
  padding: 10px 0;
}

.header_section img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.header_section .dropdown button {
  border: none;
  font-size: var(--font-size-regular);
}

.sidebar_Content {
  max-height: calc(100vh - 127px);
  overflow: auto;
  position: relative;
}

.sidebar_Content textarea {
  height: calc(100vh - 520px);
  overflow: auto;
  resize: none;
}

td i.fa-coins {
  color: var(--accent-200);
}

.swal2-confirm {
  background-color: var(--accent-200) !important;
}

div:where(.swal2-icon).swal2-success {
  border-color: var(--accent-200) !important;
  color: var(--accent-200) !important;
}

div:where(.swal2-icon).swal2-success .swal2-success-ring {
  border: 0.25em solid var(--accent-200) !important;
}

div:where(.swal2-icon).swal2-success [class^="swal2-success-line"] {
  background-color: var(--accent-200) !important;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  white-space: nowrap;
  flex-wrap: inherit !important;
}

.swal2-confirm:focus,
.swal2-default-outline:focus {
  box-shadow: none !important;
}

button.themeButton,
.swal2-actions button {
  background: var(--accent-200);
  border: none;
  width: auto;
  color: var(--bg-100);
  padding: 5px 19px;
  border-radius: 5px;
  font-size: var(--font-size-regular);
  transition: 1s;
  border: 1px solid var(--accent-200);
}

button.themeButton:hover {
  color: var(--accent-200);
  border: 1px solid var(--accent-200);
  background: transparent;
}

.sidebar_cpm {
  border-right: 1px solid var(--border-color);
}

.content_cpm {
  border-bottom: 1px solid var(--border-color);
  padding: 6.5px;
}

.content_cpm button {
  border: 1px solid var(--accent-200);
  border-radius: 3px;
}

.content_cpm input {
  padding: 2.5px;
  border-radius: 0;
  width: 150px;
}

.content_cpm button i {
  color: var(--accent-200);
  font-size: 12px;
}

.form_company {
  border-top: 1px solid var(--border-color);
  padding: 0;
}

.form_company input {
  width: 100%;
  border: none;
  outline: none;
  font-size: var(--font-size-regular);
  background: transparent;
}

.form_company button {
  border: none;
  outline: none;
  background: transparent;
  opacity: 0.7;
  transition: all 0.2s;
  cursor: pointer;
  padding: 0 0.5rem;
}

.form_company button svg {
  fill: var(--primary-100);
  width: 1.5rem;
  height: 1.5rem;
}

.form_company button.clear svg {
  fill: var(--primary-200);
}

.messages {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--bg-100);
  height: calc(100vh - 322px);
}

.messages .empty {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-regular);
  fill: var(--text-light);
}

.messages .empty p {
  opacity: 0.5;
  margin: 0;
}

.messages .empty svg {
  fill: var(--text-light);
  opacity: 0.5;
  width: 2.5rem;
  height: 2.5rem;
}

.breadcrumb {
  width: 100vw;
}

li.breadcrumb-item {
  font-size: var(--font-size-thin);
}

li.breadcrumb-item a {
  color: var(--accent-200);
}

.button_group button {
  background: transparent;
  font-size: var(--font-size-regular);
  transition: 1s;
  padding: 1.5px 7px;
  font-weight: 600;
}

.button_group button:hover {
  background: var(--accent-200);
  border-radius: 3px;
  color: var(--bg-100);
}

.button_group button:hover i,
.close_circle:hover i,
.user_filter button:hover i,
.icon {
  color: var(--bg-100) !important;
}

.createAccess {
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  opacity: 0.5;
  transition: 1s;
}

.createAccess:hover {
  opacity: 1;
}

.action__buttons i {
  color: var(--accent-200);
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action__buttons button:hover i {
  color: var(--bg-100);
}

.saperate_action_button {
  background: var(--bg-100);
  padding: 2px 4px;
  display: flex;
  gap: 5px;
  border-radius: 20pc;
  position: absolute;
  right: 43px;
  box-shadow: rgba(0, 0, 0, 0.24) -10px 13px 60px 12px;
}

.action__buttons button {
  height: 17px;
  width: 17px;
  padding: 4px;
  background: transparent;
  border: 1px solid var(--accent-200);
  transition: 1s;
}

.action__buttons button:hover,
.user_filter button:hover {
  border: 1px solid var(--accent-200);
  background: var(--accent-200);
}

.user_filter button:hover p {
  color: var(--bg-100);
}

.close_circle {
  background: transparent !important;
  border: 1px solid var(--red_color) !important;
  transition: 1s;
}

.close_circle i {
  color: var(--red_color) !important;
}

.close_circle:hover {
  background: var(--red_color) !important;
  border: 1px solid var(--red_color) !important;
}

.ql-container {
  border: 0 !important;
  height: calc(100vh - 127px) !important;
  overflow: auto;
}

.ql-snow .ql-picker-label {
  font-size: var(--font-size-regular);
  border: 0 !important;
}

.ql-toolbar.ql-snow .ql-picker-options {
  box-shadow: none !important;
  border-radius: 3px;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.para_details {
  margin: 0 auto;
  width: min(900px, calc(70% + 100px));
  position: relative;
}

pre code {
  white-space: break-spaces !important;
}

.autoProgramHeight {
  height: calc(100vh - 127px);
  overflow-x: hidden;
}

.para_detail_description pre {
  margin: 0 !important;
}

.para_detail_description p,
.para_detail_description i {
  font-weight: 500;
  color: var(--bg-100);
  font-size: 10px;
}

.resultImages img {
  width: 20px;
  border-radius: 50%;
}

.NullImages img {
  width: 70px;
}

.para_detail_description .copy_text {
  border-radius: 4px;
  cursor: pointer;
  padding: 0 5px 2px;
  position: absolute;
  right: -25px;
  top: 0;
}

.button_group .dropdown-toggle::after {
  display: none !important;
}

.button_group i {
  color: var(--text-100);
  transition: 1s;
}

.button_group .btn.show:hover {
  background-color: var(--bg-100) !important;
}

.total_data_program textarea {
  height: calc(100vh - 365px);
  overflow: auto;
  resize: none;
}

.program_aim {
  border-bottom: 1px solid var(--border-color);
  padding: 6.7px 15px;
}

.Account_sidebar {
  height: calc(100vh - 89px);
  overflow: auto;
  border-right: 1px solid var(--border-color);
  background-color: var(--bg-300);
}

.admin-main-content-area {
  padding: 1em;
  height: calc(100vh - 89px);
  overflow: auto;
}

.Account_sidebar ul,
.breadcrumb {
  margin: 0;
  padding: 0;
}

.Account_sidebar ul li {
  list-style: none;
  cursor: pointer;
  padding: 8px 15px;
  margin-bottom: 5px;
  font-size: var(--font-size-regular);
  font-weight: 600;
  border-left: 4px solid transparent;
}

.iconSizes i {
  width: 15px;
}

.Account_sidebar ul li:hover,
.leaveOptionBox .optionButton:hover,
.leaveOptionBox .optionActiveButton:hover,
.Account_sidebar a.active li,
.activeSidebarSetting {
  background-color: var(--bg-300);
  border-left: 4px solid var(--accent-200) !important;
  border-radius: 6px 0 0 6px;
  color: var(--text-100);
}

.Account_sidebar ul li ul {
  list-style: none;
  padding-left: 10px;
  margin-top: 5px;
}

.Account_sidebar a {
  text-decoration: none;
  color: var(--text-100);
}

.user_filter input {
  width: 125px;
}

.user_filter input {
  border-radius: 3px 0px 0px 3px;
  border-right: 0 !important;
  height: 30px;
  font-size: 12px;
  border: 1px solid #00619a;
}

.user_filter button {
  height: 30px;
  padding: 0px 10px;
  border-radius: 3px;
  background: transparent;
  border: 1px solid var(--accent-200);
  transition: 1s;
}

.user_filter button i,
.user_filter button p {
  font-size: var(--font-size-thin);
  color: var(--accent-200);
  background: transparent;
}

.selectTransaction .form-select {
  border-radius: 3px;
  border: 1px solid var(--accent-200);
  padding: 5px 40px 4px 10px;
  font-weight: 600;
  color: var(--accent-200);
}

.transactionId,
.paymentId {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transactionId {
  width: 350px;
}

.paymentId {
  width: 200px;
}

/* =============================================
==================Satish CSS====================
================================================ */

.userTable {
  height: calc(100vh - 98px);
  overflow: auto;
}

.userTable thead tr,
th {
  background-color: var(--accent-200) !important;
  color: var(--bg-100) !important;
  padding: 8px !important;
}

tr,
th {
  vertical-align: middle;
}

.userTable .form-select {
  padding: 2px 8px;
  font-size: var(--font-size-thin);
}

.Profile_img label {
  color: var(--text-100);
  font-size: var(--font-size-solid);
  font-weight: 600;
  margin-bottom: 5px;
}

.avatar-upload {
  position: relative;
  max-width: 190px;
  margin: 0px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 40px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 50%;
  background: var(--bg-100);
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: var(--bg-300);
  border-color: var(--bg-300);
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: var(--text-100);
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 155px;
  position: relative;
  border-radius: 100%;
  border: 1px solid var(--border-color);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview img {
  border-radius: 50%;
  object-fit: cover;
  height: 155px;
}

.icon {
  width: 40px;
  height: 40px;
  background: var(--accent-200);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.widget-data {
  padding: 0 15px;
}

.leaveOptionBox {
  background-color: var(--bg-300);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  border-top: 2px solid var(--accent-200);
  border-radius: 3px;
  padding: 5px 12px;
  cursor: pointer;
}

.optionButton {
  color: var(--text-100);
  font-size: var(--font-size-regular);
  font-weight: 600;
  margin: 5px 0;
  padding: 6px 10px;
  border-left: 4px solid transparent;
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .optionButton, .optionActiveButton {
    font-size: 12px;
  }
}

.optionActiveButton {
  color: var(--text-100);
  padding: 6px 10px;
  font-size: var(--font-size-regular);
  font-weight: 600;
  margin: 5px 0;
  background-color: var(--bg-300);
  border-left: 4px solid var(--accent-200) !important;
  border-radius: 6px 0 0 6px;
}

.modal-header {
  padding: 10px 20px;
  background: var(--accent-200);
}

.modal-title.h4 {
  font-size: var(--font-size-solid);
  font-weight: 600;
  color: var(--bg-100);
}

.modal-header .close_modal {
  transition: 1s;
  color: var(--red_color);
  background: var(--bg-100);
  border-radius: 50%;
  font-size: 11px;
  border: 1px solid var(--red_color);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  cursor: pointer;
}

.modal-header .close_modal:hover {
  transform: rotate(180deg);
}

.button-count {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: var(--accent-200);
  color: var(--bg-100);
  font-size: var(--font-size-solid);
  text-align: center;
  border: 1px solid var(--accent-200);
  outline: none;
}

.button-count:active {
  background-color: var(--accent-200);
}

.number-credits {
  width: 46px;
  height: 28px;
  font-size: var(--font-size-regular);
  border: 1px solid var(--bg-300);
  text-align: center;
  padding: 14.4px 0 13.7px;
}

/* Profile CSS  */

.profile .profile_dd {
  width: 200px;
  position: absolute;
  top: 43px;
  right: 0px;
  background: var(--bg-100);
  z-index: 99;
}

.icon_wrap .title_name:hover,
.profileNameActive {
  background: var(--bg-200);
  border-radius: 4px;
  color: var(--accent-200);
  font-weight: 900;
  transition: 1s;
}

.profile .profile_dd ul li {
  border-bottom: 1px solid #80808030;
}

.profile .profile_dd ul li a {
  display: block;
  padding: 10px 15px;
  position: relative;
  font-weight: 600;
  font-size: var(--font-size-regular);
  color: var(--accent-200);
}

.profile_ul li:last-child .logout-link:hover {
  background-color: rgba(255, 0, 0, 0.073);
}

.profile .profile_dd ul li a:hover {
  color: var(--accent-200);
  background: var(--bg-300);
}

.profile.active .profile_ul {
  max-height: 300px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.profile_ul {
  padding: 0;
  overflow: hidden;
  margin: 0;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

/* HomePage Css  */

.wrapper .card {
  border: none;
  overflow: hidden;
  border-radius: 20px;
  min-height: 450px;
}

.wrapper .card.card-has-bg {
  transition: 1s;
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: center center;
}

.wrapper .card.card-has-bg.documentAi {
  background-image: url("./image/DocumentAI.jpg");
}

.wrapper .card.card-has-bg.programAi {
  background-image: url("./image/programAI.jpg");
}

.wrapper .card.card-has-bg.Analytics {
  background-image: url("./image/Analytics.jpg");
}

.wrapper .card.card-has-bg:hover {
  transform: scale(0.98);
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
  background-size: 130%;
  transition: 1s;
}

.wrapper .card .card-title {
  font-weight: 800;
  color: var(--bg-100);
}

.card .card-footer {
  border-top: 1px solid rgb(255 255 255 / 40%);
  color: var(--bg-100);
}

.wrapper .card .card-body {
  transition: 1s;
}

.wrapper .card:hover .card-body {
  margin-top: 30px;
  transition: 1s;
}

.wrapper .card .card-img-overlay {
  transition: 1s;
  background: var(--accent-200);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.435) 0%,
    var(--accent-200) 100%
  );
}

.media-body button:hover {
  color: var(--bg-100) !important;
  border: 1px solid var(--bg-100) !important;
}

/* Login Page CSS  */

.login_page {
  min-height: 100vh;
  display: grid;
}

.login_page .card {
  place-self: center;
  width: 80%;
}

.LoginImg img {
  height: 100vh;
  object-fit: cover;
}

.passwordvisiability {
  position: absolute;
  right: 10px;
  top: 8px;
  font-size: var(--font-size-regular);
  color: var(--accent-200);
  cursor: pointer;
}

.message a {
  color: var(--accent-200);
}

.otp-input-fields {
  width: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  margin: 0 auto;
}

.qr__imag {
  display: flex;
  justify-content: center;
}

.qr__imag img {
  width: 225px;
}

.qr__imag .security-shield,
.qr__imag img {
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 #63636333;
}

.qr__imag .security-shield {
  padding: 20px 50px;
  text-align: center;
}

.qr__imag .security-shield i {
  font-size: 150px;
  color: var(--accent-200);
}

.otp-input-fields input {
  height: 35px;
  width: 35px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--accent-200);
  text-align: center;
  outline: none;
  font-size: 16px;
}

/* Pagination Css ============ */

.pagination {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.paginationResultCount {
  font-weight: bold;
  font-size: 12px;
}

.paginate {
  font-size: 12px;
  overflow: auto;
}

.paginate ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 5px;
}

.paginate ul a {
  margin: 0 0.25rem;
  font-weight: 500;
}

.paginate ul li.selected a {
  font-weight: bold;
}

.paginate ul li.selected {
  background: var(--bg-300);
  font-weight: bold;
}

.paginate ul li a {
  padding: 7.2px 10px;
  color: var(--bg-100);
}

.paginate ul li {
  border: 1px solid var(--accent-200);
  padding: 5.3px 0px;
  border-radius: 3px;
}

.paginate ul li:first-child {
  background-color: var(--accent-200);
  font-weight: bold;
  color: var(--bg-100);
}

.paginate ul li:last-child {
  background-color: var(--accent-200);
  font-weight: bold;
  color: var(--bg-100);
  margin-right: 0;
}

.paginate li {
  font-size: var(--font-size-regular);
}

/* Thnaks Page CSS  */

@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}

@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}

@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }

  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}

.thanksSection {
  text-align: center;
  margin-top: 30px;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;
}

.confetti--animation-slow {
  animation: confetti-slow 2.25s linear 1 forwards;
}

.confetti--animation-medium {
  animation: confetti-medium 1.75s linear 1 forwards;
}

.confetti--animation-fast {
  animation: confetti-fast 1.25s linear 1 forwards;
}

/* Invitation CSS ======= */

.Invitation_form {
  border-radius: 4px;
  width: 80%;
  background: var(--bg-300);
}

.Form_justification {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.invite__form_message {
  border-radius: 10px;
  box-shadow: 0 3px 8px #0000003d;
  max-width: 550px;
  padding: 20px;
  text-align: center;
}

.invite__form p,
.invite__form_message p {
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  padding-bottom: 10px;
  text-transform: uppercase;
}

.Success__taag {
  display: flex;
  justify-content: center;
  text-align: center;
}

.Success__taag i {
  font-size: 60px;
}

.filterDropdown {
  padding: 1px 30px 0 8px;
  font-size: var(--font-size-thin);
  font-weight: 600;
  border-radius: 3px;
}

.transactionTable th,
.transactionTable table {
  --bs-table-bg: transparent !important;
  background: transparent !important;
  color: inherit !important;
}

/* Floating Print Button  */

.printFloatingButton {
  position: fixed;
  bottom: 20px;
  right: 50%;
}

@media print {
  .printButton {
    display: none;
  }
}

.spin_loader {
  margin-right: 5px !important;
}

#menu-btn {
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--bg_active_color);
  font-size: 15px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#menu-btn:hover {
  background-color: var(--accent-200);
  color: var(--bg-100);
  transition: 1s;
}

@media (max-width: 579px) {
  #menu-btn {
    width: 35px;
  }
}

.collapsed {
  transition: 1s;
}

.rightHeightColumn {
  height: calc(100vh - 105px);
}

.tableLibrary.custom-table th:last-child {
  width: 100% !important;
  table-layout: fixed;
}

.tableLibrary.custom-table {
  width: auto;
  max-width: max-content;
}

.custom-table {
  width: 100%;
  table-layout: fixed;
}

.custom-table.resizing {
  width: 100%;
  max-width: fit-content;
}

.custom-table th {
  resize: horizontal;
}

.custom-table th,
.custom-table td {
  border: 1px solid #c1b5b55e;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.tableLibrarySection {
  background: #103c5e14;
}

.apexcharts-datalabel-label {
  font-size: 11px !important;
}
.chip {
  border: 1px solid var(--accent-200);
  width: fit-content;
  border-radius: 3px;
  margin-right: 5px;
}

.chip span {
  font-size: 10px;
  padding: 3px 5px;
  background: var(--accent-200);
  color: var(--bg-100);
}

.chip .vr {
  background-color: var(--accent-200);
  opacity: 1;
  width: 0.9px;
}

.chip i {
  font-size: 10px;
  cursor: pointer;
  padding: 0 5px;
  color: var(--accent-200);
}

.badgesChips {
  display: ruby-text;
  text-align: left;
}

.fixRightButtoon {
  position: sticky;
  bottom: 35px;
  background: #fff;
  padding: 10px;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
