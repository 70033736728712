a {
  text-decoration: none;
  color: var(--bg-100);
}

.form-text {
  font-size: 11px;
}

.form-label {
  font-weight: 600;
}

.form-select,
.form-control,
.form-label,
.add_news {
  font-size: 13px;
  transition: 1s;
  /* border-radius: none !important; */

}

.footer_section{
  position: absolute;
  bottom: 0;
  width: 100%;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.form-select:focus,
.form-control:focus,
.form-check-input:focus,
.btn:focus-visible  {
  border-color: var(--accent-200);
  box-shadow: none;
}

.form-select:hover,
.form-control:hover,
.form-check-input:focus {
  border-color: var(--accent-200);
}

p,
.dropdown-item {
  font-size: var(--font-size-regular);
}
.dropdown-item.active,
.dropdown-item:active {
  background: var(--accent-200);
}
.h4,
h4 {
  font-size: var(--font-size-solid);
}

.form-label span{
  color: var(--red_color);
}

.table {
  margin: 0;
}

.libraryTables tr:hover {
  /* --bs-table-hover-color: var(--bg-100);
  --bs-table-hover-bg: var(--accent-200); */
  cursor: pointer;
}

.table > thead {
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 9;
}

.table > :not(caption) > * > * {
  padding: 5px 8px;
  font-size: var(--font-size-regular);
}

.form-check-input:checked {
  background-color: var(--accent-200);
  border-color: var(--bg-100);
  cursor: pointer;
}

:disabled {
  background-color: var(--bg-300) !important;
  border: 1px solid var(--bg-300) !important;
  color: var(--accent-200) !important;
  cursor: no-drop;
}

img {
  width: 100%;
}

.card{
  background: var(--bg-300);
}

.ql-editor {
  height: calc(100vh - 128px) !important;
}

.ql-toolbar.ql-snow{
  display: none;
}