.w-20 {
  width: 20%;
}

.w-0 {
  width: 0%;
}

.w-80 {
  width: 80%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .w-20 {
    width: 30%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .home_page_section {
    display: inline-block;
  }
  
  .sidebar_Content textarea {
    height: calc(100vh - 545px);
  }

  .LoginImg {
    display: none;
  }

  .pagination {
    display: block;
  }

  .custom-table {
    width: auto;
  }

  .avatar-upload .avatar-edit {
    right: 0;
    top: 0;
  }
  
  .w-20 {
    width: 40%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
